.team-create-modal {
  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 40px;
  }

  .ant-radio-button-wrapper {
    width: calc(100% / 3);
    height: 40px;
    text-align: center;
    padding: 5px 15px;
    border: 1px solid #27282e;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      color: #9174e3;
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #9174e3;
    border-color: #9174e3;

    &:hover {
      border-color: #3c0594;
      background: #3c0594;
    }
  }

  .ant-radio-button-wrapper-checked {
    &:not([class*=' ant-radio-button-wrapper-disabled']) {
      &.ant-radio-button-wrapper {
        &:first-child:not(:hover) {
          border-color: #9174e3;
        }
      }
    }
  }

  .ant-radio-button-wrapper {
    &:not(.first-child) {
      &::before {
        display: none;
      }
    }
  }
}
