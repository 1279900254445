.team-dropdown-menu {
  right: 40px;
  border-radius: 8px;
  top: 5px;
  max-height: 480px;
  overflow: scroll;

  &.light {
    background: #ffffff;
  }
}
