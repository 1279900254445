@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&family=Public+Sans:wght@400;800&display=swap');

* {
  font-family: Noto Sans, sans-serif;
}

html {
  font-size: 62.5%;
  text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
}

label,
input[type="submit"],
button {
  cursor: pointer;
}

/* ----------- Flex ------------- */
.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* ----------- Ant design customize ------------- */
a {
  color: #551c9d;
}

.ant-card-body {
  padding: 16px !important;
}

.ant-table-thead > tr > th {
  background: none !important;
}

.ant-table-pagination-right {
  justify-content: center !important;
}

.ant-table-pagination.ant-pagination {
  margin: 60px 0 !important;
}

.ant-upload.ant-upload-drag {
  border: 1px dashed #4797ff !important;
}

.ant-modal-body {
  padding: 46px;
}

.ant-table {
  background-color: transparent !important;
}

.ant-list-item-meta {
  align-items: center !important;
}

.ant-list-item-meta-title {
  margin: 0 !important;
}

.box {
  border: 1px solid #434343;
  width: 200px;
  height: 130px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.box:hover {
  border: 1px solid #6f6eff;
  transition: 0.3s;
}

/* AntD Select */
.ant-select .ant-select-selector {
  height: 100%;
}

.ant-select .ant-select-arrow .anticon {
  margin-top: 8px;
}

.ant-radio-button-wrapper-checked {
  background: #9174e3;
}

.ant-switch-checked {
  background-color: #6f6eff !important;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #6f6eff !important;
}

.s-ant-info-modal .ant-btn-primary {
  background: #6f6eff !important;
  border: none;
}

.ant-menu {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

.ant-select-selection-search-input {
  display: none;
}

.spearly-cms-content h1,
.spearly-cms-content h2,
.spearly-cms-content h3 {
    color: #4f4f4f;
}

.spearly-terms-confirm {
  color: #8083a3;
}
