.flag-item {
  font-size: 16px !important;
  padding: 10px 12px !important;
  &:hover {
    background: #181a21 !important;
    border-radius: 4px !important;

    &.light {
      background: #e4def9 !important;
    }
  }
}

.flag-img {
  margin-right: 7px;
}

.flagList {
  background: #292932 !important;
  border: 1px solid #44444f !important;
  box-shadow: 0 5px 15px rgba(68, 68, 79, 0.1) !important;
  border-radius: 8px !important;
  padding: 12px !important;

  &.light {
    background: #ffffff !important;
    border: 1px solid #ffffff !important;
  }
}

.flag-list-opener {
  border-color: transparent !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}

.arrow {
  &.light {
    filter: brightness(0);
  }
}
