.checkout-modal {
  position: relative;

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 46px 40px 49px;
  }
}
