.invitationList__status {
  &.-pending {
    background: rgba(255, 231, 171, .3);
    color: #f6ba20;
  }

  &.-expired {
    background: rgba(255, 152, 159, .15);
    color: #ff616a;
  }
}
